import React from "react"

import { Subscribe } from "unstated"
import WaitlistStore from "../../stores/waitlists"

import WaitlistApp from "../../components/WaitlistApp"
import Layout from "./components/_Layout"

function Waitlist() {
  return (
    <Layout name="Waitlist" title="Manage your waitlisted items">
      <Subscribe to={[WaitlistStore]}>
        {waitlistStore => (
          <WaitlistApp
            waitlists={waitlistStore.state.waitlists}
            delWaitlistItem={waitlistStore.delWaitlistItem}
            fetchWaitlist={waitlistStore.fetchWaitlist}
          />
        )}
      </Subscribe>
    </Layout>
  )
}

export default Waitlist
