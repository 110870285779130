import React, { useEffect } from "react"
import styled from "styled-components"

import WaitlistCard from "./WaitlistCard"
import SectionHeader from "../pages/settings/components/_SectionHeader"
import Table from "../pages/settings/components/_Table"

import emptyBox from "../imgs/guy-box-empty.svg"

const NothingHereImg = styled.img`
  margin: 0 auto;
  float: none;
  display: block;
  max-width: 90%;
`

function WaitlistApp({ delWaitlistItem, waitlists, fetchWaitlist }) {
  useEffect(() => {
    fetchWaitlist()
  }, [])

  return (
    <React.Fragment>
      <SectionHeader title="Waitlist" />

      {waitlists.length < 1 ? (
        <NothingHereImg src={emptyBox} alt="Nothing here" />
      ) : (
        <Table>
          <thead>
            <tr>
              <th className="strong left">item</th>
              <th className="strong left">Added</th>
              <th className="" />
            </tr>
          </thead>
          <tbody>
            {waitlists.map(w => {
              return (
                <WaitlistCard
                  key={w.id}
                  handleDelete={() => delWaitlistItem(w.id)}
                  {...w}
                />
              )
            })}
          </tbody>
        </Table>
      )}
    </React.Fragment>
  )
}

export default WaitlistApp
